import React from 'react';
import './TextBoxWithIcon.css'; // Make sure to create this CSS file to style the input

const TextBoxWithIcon = ({maxLength=1000, required=false, Img, imgColor, placeholder, inputType, value, onChange, wrapperStyle={}, inputStyle={}, className="", wrapperClassName="" }) => {
    return (
        <div className={"input-container "+wrapperClassName} style={wrapperStyle}>
            {React.createElement(Img, { className: "icon", style: {color: imgColor} })}
            <input
                type={inputType}
                placeholder={placeholder}
                className={"input-field "+className}
                value={value}
                onChange={onChange}
                style={inputStyle}
                maxLength={maxLength}
            />
        </div>
    );
};
export default TextBoxWithIcon;