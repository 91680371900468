import './LoginRegister.css'
import {useEffect, useRef, useState} from "react";
import loginBGTop from '../../../assets/login-register/loginBGTop.png'
import loginBGBottom from '../../../assets/login-register/loginBGBottom.png'
import axios from "axios";
import {
    CustomErrorToast,
    CustomLoadingToast, CustomSuccessToast,
    CustomToastContainer, DismissToast,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import checkEmail from "./Utilities";
import {Link, useNavigate, useParams} from "react-router-dom";
import FlexBox from "../../../components/utilities/FlexBox";
import { getUrlParameter} from "../../../utilities/Utilities";

import conff from '../../../assets/login-register/confirmation.png'
import {API_URL_PREFIX, APP_URL_PREFIX, FULL_PUBLIC_PREFIX} from "../../../utilities/Constants";
import ModalBackground from "../../../components/utilities/modal/ModalBackground";
import InputText from "../../../components/utilities/InputText";
import Divider from "../../../components/utilities/Divider";
import {Button} from "react-bootstrap";
import Switch from "react-switch";
import LogoImg from '../../../assets/newLogo.svg'
import TextBoxWithIcon from "../../../components/utilities/TextBoxWithIcon";
import {Building, Envelope, Lock, Person, ShieldLock} from "react-bootstrap-icons";
import ProfileImage from "../../../components/profile/ProfileImage";

const LoginRegister = () => {
    const params = useParams();
    let flow = "";
    if(params.flow){
        flow = params.flow;
    }
    useEffect(() => {
        document.title = "Login / Register"
    }, [])
    const [reset, setReset] = useState(flow == 'reset')
    const [login, setLogin] = useState(flow == 'login')
    const [confirmation, setConfirmation] = useState(false)

    useEffect(()=>{
        setReset(flow == 'reset')
        setLogin(flow == 'login')
    },[flow])
    return (
        <FlexBox className={"loginWrapper"} justify={"center"} align={"center"} style={{width: "100vw", height: "100vh"}}>
            <FlexBox justify={"space-between"} align={"center"} style={{width:"70%", minWidth:"min(1000px, 100%)", minHeight:"500px", height:(login ? "500px": "800px")}} className={"LoginContent"}>

                <FlexBox justify={"center"} align={"start"} direction={"column"} className={"p20"} style={{overflow:"hidden" ,position:"relative" ,borderRadius:"40px 0px 0px 40px" ,paddingLeft:"120px",background:"#774CF3", width:"55%", height:"100%", color:"white"}}>
                    <h2 style={{color:"white", fontWeight:"bold", fontSize:"50px"}}>Welcome {login ? "Back" : ""} <br/>to Lugo-Test</h2>
                    <p style={{marginTop:"10px", fontSize:"20px"}}>Technical Hiring Platform</p>
                    <div style={{marginTop:"10px", borderTop:"1px solid #fff", width:"80px"}}></div>

                    <img src={loginBGTop} style={{position:"absolute", top:"-75px", left:"25px", width:"auto", height:"150px"}}/>
                    <img src={loginBGBottom} style={{position:"absolute", bottom:"-95px", right:"10px", width:"auto", height:"250px"}}/>

                </FlexBox>
                <FlexBox className={"p20"} style={{background:"#fff", width:"45%", height:"100%", borderRadius:"0px 40px 40px 0px"}}>
                    <CustomToastContainer/>
                    {confirmation ? <Confirmation /> : <Content reset={reset} setReset={setReset} setLogin={setLogin} login={login} setConfirmation={setConfirmation}/>}
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}

function Login({setLogin}) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(true)
    const [sentActive, setSentActive] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const navigate = useNavigate()
    const [err, setErr] = useState(false)

    useEffect(()=>{
        if(getUrlParameter("active") == 1 && !sentActive){
            CustomSuccessToast("Your Company has been activated successfully!")
            setSentActive(true);
        }
        else if(getUrlParameter("errInfo") && !sentActive){
            CustomErrorToast(getUrlParameter("errInfo"));
            setSentActive(true);
        }
    },[])

    const login = () => {
        setErr(false)
        const id = CustomLoadingToast("Processing")
        let url = API_URL_PREFIX+ "/LoginLiteCompany.html";
        let bodyFormData = new FormData();
        bodyFormData.append('email', email.toLowerCase());
        bodyFormData.append('password', password);

        let data = {email: email, password: password}
        if(getUrlParameter("tk")){
            data["tk"] = getUrlParameter("tk")
        }

        axios.post(url, data)
            .then((res) => res.data)
            .then(res => {
                console.log(res)
                if (res.result === true) {
                    updateCustomSuccessToast("Success!", id)
                    navigate(APP_URL_PREFIX + "/dashboard");
                } else {
                    setErr(true)
                    updateCustomErrorToast(res.info, id)
                }
            })
            .catch(error => {
                updateCustomErrorToast("You have entered an invalid email or password", id)
            })
    }

    return <div className={"fadeInElement"} onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            login()
        }
    }}>
        <FlexBox style={{fontWeight: '300', width:"100%", marginTop:"25px"}} direction={"column"} justify={"left"} align={"start"}>
            <TextBoxWithIcon
                placeholder={"Email"}
                Img={Envelope}
                imgColor={"#C0C0C0"}
                altText={"email"}
                inputType={"text"}
                value={email}
                onChange={e => setEmail(e.target.value)}
                className={"form-control"}
                wrapperClassName={"input"}
            />

            <br/>
            <TextBoxWithIcon
                placeholder={"Password"}
                Img={ShieldLock}
                imgColor={"#C0C0C0"}
                altText={"password"}
                inputType={"password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                className={"form-control"}
                wrapperClassName={"input"}
            />

        </FlexBox>
        <span hidden={!err} id={"errIndicator"}> </span>

        <FlexBox style={{fontWeight: '300', color:"#C0C0C0", fontSize:"13px", marginTop:"10px"}} justify={"end"}>

            <span>
                <Link  style={{color:"#774CF3", textDecoration:"none", fontWeight:"bold", fontSize:"13px"}} to={"#"} onClick={e => setForgotPassword(true)}>Forgot Password</Link>
            </span>
        </FlexBox>
        <br/>
        <br/>
        <button disabled={!checkEmail(email) || password.length < 6} onClick={login} className={"loginButton"}>Login
        </button>
        <br/>
        <br/>
        <br/>
        <div style={{color:"#C0C0C0", width:"100%", textAlign:"center", fontSize:"13px"}}>Don't have an account yet? <Link  style={{color:"#774CF3", textDecoration:"none", fontWeight:"bold", fontSize:"13px"}} to={"#"} onClick={e => setLogin(false)}>Sign Up</Link>.</div><br/>

        {forgotPassword && <ForgotPassword setShow={setForgotPassword} />}
    </div>
}

const Confirmation = ()=>{
    return (
        <div style={{
            flexGrow: 2,
            display: "flex",
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        }} className={"confirmation"}>
        <img src={conff} />
        <h4>
            Congratulations! Your company as been created successfully.<br/>
            Please check your registered email for verification and further details.
        </h4>
        </div>
    )
}

const Content = ({login, setLogin, setConfirmation,setReset,reset}) => {
    return (<div style={{
        flexGrow: 2,
        display: "flex",
        width: "40%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }}>
        <div style={{display: "flex", width: "330px", justifyContent: "center", flexDirection: "column", alignItems:"center"}}>
            <a href={"https://lugo-test.com/"} style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "20px",
                alignItems: "center",
                textDecoration:"none",
                width:"260px",
                marginBottom:"25px"
            }} className={"page no-hover-background"}>
                <img src={LogoImg} alt={"home"} />
                <div style={{fontWeight:"bold", fontSize:"30px"}}>Lugo-Test</div>
            </a>

            <div style={{width:"100%"}}>
                {reset ? <Reset reset={reset} setReset={setReset} /> : <>
                    {login ?
                        <Login setLogin={setLogin}/> :
                        <Register setConfirmation={setConfirmation} setLogin={setLogin}/> }
                </>}
            </div>
        </div>
    </div>)
}

const Reset = ({setReset,reset}) => {

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [done, setDone] = useState(false)


    const resetPassword = () => {
        const id = CustomLoadingToast("Processing")

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if(!passwordRegex.test(password)){
            updateCustomErrorToast("Password is too weak.",id)
            return;
        }

        if (!password || password.length < 6) {
            updateCustomErrorToast("Password must contain 8-30 characters.",id)
            return;
        }
        if (password != confirmPassword) {
            updateCustomErrorToast("Passwords do not match.",id)
            return;
        }

        axios.post(API_URL_PREFIX+"/ResetPassword.html", {password, token:getUrlParameter("t")})
            .then(res => {
                let data = res.data
                if (data && data.result == true) {
                    DismissToast(id)
                    setDone(true)
                } else {
                    if (data && data.info) {
                        updateCustomErrorToast(data.info, id)
                    }
                    else {
                        updateCustomErrorToast("Error, Please try again.", id)
                    }
                }
            })
            .catch(error => {
                updateCustomErrorToast("Error, Please try again.", id)
            })
    }
    if(done){
        return (
            <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
                <p style={{fontSize:"18px", textAlign:"center", border:"1px solid #000", padding:"25px 15px", background:"#774CF3", color:"white"}}>Your password has been changed.<br/>Click <a style={{color:"white"}} href={"/users/login"}>here</a> to login.</p>

            </FlexBox>
        )
    }

    return (<div onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            resetPassword()
        }
    }}>

        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <h3 style={{textDecoration:"none", textAlign:"center", width:"100%", fontWeight:200}}>Reset your password</h3>
            <br/>

            <TextBoxWithIcon
                placeholder={"New Password"}
                Img={ShieldLock}
                imgColor={"#C0C0C0"}
                altText={"password"}
                inputType={"password"}
                value={password}
                onChange={e => {
                    setPassword(e.target.value);
                }}
                wrapperClassName={"form-control" + " input afterWrapper "}
                className={"input"}
                required={true}
                maxLength={30}
            />
            <br/>

            <TextBoxWithIcon
                placeholder={"Confirm New Password"}
                Img={ShieldLock}
                imgColor={"#C0C0C0"}
                altText={"password"}
                inputType={"password"}
                value={confirmPassword}
                onChange={e => {
                    setConfirmPassword(e.target.value);
                }}
                wrapperClassName={"form-control" + " input afterWrapper " }
                className={"input"}
                required={true}
                maxLength={30}
            />

            <span style={{textAlign:"left",marginBottom: "15px", fontSize: "13px", color: "#C0C0C0"}}>Your password must be at least 8 characters long, include both upper and lower case letters, and contain at least one special character.</span>
        </FlexBox>
        <br/>
        <button onClick={resetPassword} className={"loginButton"}>Save Changes</button>
    </div>)
}



const Register = ({setLogin, setConfirmation}) => {
    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [email, setEmail] = useState("")
    const [status, setStatus] = useState({})
    const [isCompany, setIsCompany] = useState(true)

    const register = () => {
        let stat = {}

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if (!password || password.length < 8) {
            stat = ({...stat, p: true})
        }

        if(!passwordRegex.test(password)){
            stat = ({...stat, pe: true})
        }


        if (password != confirmPassword) {
            CustomErrorToast("Passwords do not match")
            stat = ({...stat, cp: true})
        }
        if(isCompany){
            if (!name || name.length < 2) {
                stat = ({...stat, u: "short"})
            }
        }
        if (!email || email.length < 2) {
            stat = ({...stat, e: "short"})
        }
        if (!firstName || firstName.length < 2) {
            stat = ({...stat, fu: "short"})
        }
        if (!lastName || lastName.length < 1) {
            stat = ({...stat, lu: "short"})
        }
        setStatus(stat)
        if (Object.values(stat).find(e => (e === true || e === "short" || e == "taken"))) return;
        const tk = getUrlParameter("tk")
        const referrer = getUrlParameter("ref")
        const id = CustomLoadingToast("Processing")
        let payload = {name: name, password: password, email: email, isCompany:!!isCompany, firstName, lastName};
        if(referrer){
            payload.referrer = referrer;
        }
        if(tk){
            payload.tk = tk;
        }
        axios.post(API_URL_PREFIX+"/RegisterLiteCompany.html", payload)
            .then(res => {
                let data = res.data
                if (data.result == true) {
                    DismissToast(id)
                    setConfirmation(true)
                } else {
                    if (data.info == "email") {
                        setStatus(({...stat, e: "taken"}));
                        updateCustomErrorToast("Email address already exists.", id)
                    } else if (data.info == "invalid") {
                        setStatus(({...stat, e: true}));
                        updateCustomErrorToast("Email address is invalid.", id)
                    }
                    else if (data.info == "no-work-domain") {
                        setStatus(({...stat, e: "no-work-domain"}));
                        updateCustomErrorToast("Email is not associated with known workplace domains.", id)
                    }
                    else {
                        updateCustomErrorToast("Error creating user, Please try again.", id)
                    }
                }
            })
            .catch(error => {
                updateCustomErrorToast("Error creating user, Please try again.", id)
            })
    }


    return (<div className={"fadeInElement"} onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            register()
        }
    }}>




        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <BusinessSwitch isCompany={isCompany} setIsCompany={setIsCompany} e={status?.e}/>
            <br/>
            <div hidden={!isCompany} style={{width:"100%"}}>


                <TextBoxWithIcon
                    placeholder={"Company Name"}
                    Img={Building}
                    imgColor={"#C0C0C0"}
                    altText={"company"}
                    inputType={"text"}
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                        if (e.target.value.length > 1) {
                            setStatus({...status, u: null})
                        }
                    }}
                    wrapperClassName={"form-control" + " input afterWrapper " + " " + (status.u ? " error " : "") + (status.u == 'short' ? " usernameErr " : " ")}
                    className={"input"}
                    required={true}
                    maxLength={30}
                />
                <br/>
            </div>

            <FlexBox style={{gap:"15px"}}>
                <div>
                    <TextBoxWithIcon
                        placeholder={"First Name"}
                        Img={Person}
                        imgColor={"#C0C0C0"}
                        altText={"first Name"}
                        inputType={"text"}
                        value={firstName}
                        onChange={e => {
                            setFirstName(e.target.value);
                            if (e.target.value.length > 1) {
                                setStatus({...status, u: null})
                            }
                        }}
                        wrapperClassName={"form-control" + " input afterWrapper " + " " + (status.u ? " error " : "") + (status.fu == 'short' ? " firstNameErr " : " ")}
                        className={"input"}
                        required={true}
                        maxLength={30}
                    /><br/>
                </div>

                <div>

                    <TextBoxWithIcon
                        placeholder={"Last Name"}
                        Img={Person}
                        imgColor={"#C0C0C0"}
                        altText={"first Name"}
                        inputType={"text"}
                        value={lastName}
                        onChange={e => {
                            setLastName(e.target.value);
                            if (e.target.value.length > 1) {
                                setStatus({...status, u: null})
                            }
                        }}
                        wrapperClassName={"form-control" + " input afterWrapper " + " " + (status.u ? " error " : "") + (status.lu == 'short' ? " lastNameErr " : " ")}
                        className={"input"}
                        required={true}
                        maxLength={30}
                    /><br/>
                </div>

            </FlexBox>


            <TextBoxWithIcon
                placeholder={(isCompany ? "Business " : "") + "Email"}
                Img={Envelope}
                imgColor={"#C0C0C0"}
                altText={"email"}
                inputType={"email"}
                value={email}
                onChange={e => setEmail(e.target.value)}
                wrapperClassName={"form-control" + " input afterWrapper " + " " + (status.e ? " error " : "") +   (status.e == 'short' ? "shortEmailErr" : "") + (status.e == 'taken' ? "emailErr" : "")+ ((status.e == "no-work-domain" && isCompany) ? "emailWPErr" : "") + " " + (status.e === true ? "emailInvalid" : "") + " " + ((status.e == 'valid' && email) ? "emailValid" : "")}
                className={"input"}
                required={true}
                maxLength={128}
            />

            <small hidden={!isCompany} style={{textAlign:"left", width:"100%", color:"#C0C0C0", fontSize:"13px"}}>* Must be business/workplace email domain.</small>
            <br/>
            <TextBoxWithIcon
                placeholder="Password"
                Img={ShieldLock}
                imgColor={"#C0C0C0"}
                altText={"password"}
                inputType={"password"}
                value={password}
                onChange={e => {
                    setPassword(e.target.value);
                    if (e.target.value.length > 1) {
                        setStatus({...status, p: null})
                    }
                }}
                wrapperClassName={"form-control" + " input afterWrapper " + " " + (status.p ? " error " : "") + (status.p ? "passwordErr" : "")+ (status.pe ? " passwordErrRegex" : "")}
                className={"input"}
                required={true}
                maxLength={30}
            /><br/>

            <TextBoxWithIcon
                placeholder="Confirm Password"
                Img={ShieldLock}
                imgColor={"#C0C0C0"}
                altText={"confirm password"}
                inputType={"password"}
                value={confirmPassword}
                onChange={e => {
                    setConfirmPassword(e.target.value);
                }}
                wrapperClassName={"form-control" + " input  "}
                className={"input"}
                required={true}
                maxLength={30}
            />

            <span style={{textAlign:"left",marginBottom: "15px", fontSize: "13px", color: "#C0C0C0"}}>Your password must be at least 8 characters long, and include both upper and lower case letters</span>
            <br/>
            <div hidden={!isCompany} style={{textAlign:"center", margin:"15px 0px", fontWeight:"bold", fontSize:"16px", width:"100%", color:"#774CF3"}}>Free tests for business accounts!</div>
        </FlexBox>
        <button onClick={register} className={"loginButton"}>Sign Up</button>


        <br/>
        <br/>
        <div style={{color:"#C0C0C0", width:"100%", textAlign:"center", fontSize:"13px"}}>Already have an account? <Link  style={{color:"#774CF3", textDecoration:"none", fontWeight:"bold", fontSize:"13px"}} to={"#"} onClick={e => setLogin(true)}>Login</Link>.</div><br/>

    </div>)
}

const RadioDiv = ({selected, onClick, label}) => {
    return  <FlexBox justify={"center"} style={{gap:"15px" ,border:"1px solid "+(selected?"#774CF3":"#C0C0C0"), width:"40%", borderRadius:"5px", fontSize:"14px", height:"30px"}}>
        <input checked={selected} onClick={onClick} type={"radio"} />
        {label}
    </FlexBox>
}

const BusinessSwitch = ({isCompany, setIsCompany, e}) =>{
    return <>

        <FlexBox justify={"space-between"} style={{width:"100%"}}>
            <RadioDiv onClick={()=>setIsCompany(true)} selected={isCompany} label={"Business"} />
            <RadioDiv onClick={()=>setIsCompany(false)} selected={!isCompany} label={"Personal"} />
        </FlexBox>
        <div style={{textAlign:"center", marginBottom:"10px"}} hidden={e!="no-work-domain"}>For private businesses, contact us at <a href={"mailto:info@lugo-test.com"}>info@lugo-test.com</a> for 2 free demo tests.</div>
    </>
}


const ForgotPassword = ({setShow}) =>{
    const [email, setEmail] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const ref = useRef()


    const mouseOutHandle = e=>{
        setShow(show =>{
            if(show){
                if(ref.current && !ref.current.contains(e.target)){
                    return false;
                }
            }
            return show;
        })
    }

    useEffect(()=>{
        document.addEventListener('mousedown',mouseOutHandle)
        return (()=>{
            document.removeEventListener('mousedown',mouseOutHandle)
        })
    },[])

    const resetPassword = ()=>{
        if(!email || !checkEmail(email)) {
            CustomErrorToast("Invalid Email")
            return;
        }
        const id = CustomLoadingToast("Processing...")
        axios.post(API_URL_PREFIX+"/ResetPasswordRequest.html",{email})
            .then(e=>{
                let data = e.data;
                if(data && !data.err){
                    setSubmitted(true)
                    DismissToast(id)
                }
                else{
                    if(data.info){
                        updateCustomErrorToast(data.info,id)
                    }
                    else{
                        updateCustomErrorToast("Error, please try again.",id)
                    }
                }
            })
            .catch(err=>{
                updateCustomErrorToast("Error, please try again.",id)
                console.log(err)
            })
    }

    return (
        <ModalBackground>

            <FlexBox refVar={ref} direction={"column"} justify={"start"} className={"hard-shadow"}
                     style={{borderRadius:"7px",fontWeight:"400",width:"80%", maxWidth:"300px", background:"white", padding:"15px"}}>
                {submitted ? <p style={{width:"100%", textAlign:"center"}}>Reset instructions has been sent to {email}</p> :
                    <>
                    <h3>Reset Password</h3>
                    <Divider />

                    <InputText marginRight={"0"} width={"100%"} field={email} setField={e=>setEmail(e.target.value)} label={"Email"} required={true} />
                    <br/>
                    <Button onClick={resetPassword} variant={"success"}>Reset Password</Button>
</>
                }
            </FlexBox>

        </ModalBackground>
    )
}

const ResetPassword = () =>{

}


export default LoginRegister

